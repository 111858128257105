import styled from 'styled-components'
import { rem, grid } from 'styled-tidy'
import media from 'lib/styles/media'

export default styled.ul`
  ${grid(1, 24)}
  margin-bottom: ${rem(32)};
  margin-left: 0;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.large`
    grid-template-columns: repeat(3, 1fr);
  `}
`
