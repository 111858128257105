export default [
  {
    title: 'rem',
    code:
      `const Example = styled.div\`
        width: \${rem(42)};
      \`;`,
  },
  {
    title: 'transparentize',
    code:
      `const red = '#FF0000';
      const Example = styled.div\`
        color:
          \${transparentize(red, 0.5)};
      \`;`,
  },
  {
    title: 'opacify',
    code:
      `const red = 'rgba(255,0,0,0.5)';
      const Example = styled.div\`
        color: \${opacify(red, 0.2)};
      \`;`,
  },
  {
    title: 'flex',
    code:
      `// direction, align, justify
      const Example = styled.div\`
        \${flex('row', 'center', 'left')}
      \`;`,
  },
  {
    title: 'grid',
    code:
      `// columns, col-gap, ?row-gap
      const Example = styled.div\`
        \${grid(4, 10, 20)}
      \`;`,
  },
  {
    title: 'position',
    code:
      `// top, ?right, ?bottom, ?left
      const Example = styled.div\`
        \${position('absolute', 0)}
      \`;`,
  },
]
