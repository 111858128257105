import React, { useRef } from 'react'
import matchers from 'lib/code-blocks/matchers'
import mixins from 'lib/code-blocks/mixins'
import mediaQueries from 'lib/code-blocks/media-queries'
import Project from 'components/project'
import Badges from 'components/badges'
import CodeGrid from 'components/code-grid'
import CodeCard from 'components/code-card'

export default () => {
  const matchersBookmarkRef = useRef();
  const mixinsBookmarkRef = useRef();
  const mediaQueriesBookmarkRef = useRef();
  const codeGrids = [
    {
      id: 'matchers',
      ref: matchersBookmarkRef,
      name: 'Matchers',
      blocks: matchers,
    },
    {
      id: 'mixins',
      ref: mixinsBookmarkRef,
      name: 'Mixins',
      blocks: mixins,
    },
    {
      id: 'media-queries',
      ref: mediaQueriesBookmarkRef,
      name: 'Media Queries',
      blocks: mediaQueries,
    },
  ]
  const subnav = [
    { to: '/', name: '←', label: 'Back' },
    ...codeGrids,
  ]

  return (
    <Project name="Styled-Tidy" subnav={subnav}>
      <div>
        <p>
          Tidy is a lightweight set of utilities for writing clean styled-components syntax in React.
        </p>
        <Badges>
          <img
            src="https://camo.githubusercontent.com/4abcec840d4acc079f6b3b6f13beb8a2f953a1fd/68747470733a2f2f62616467656e2e6e65742f6769746875622f72656c656173652f6477322f7374796c65642d74696479"
            alt="Release Version"
          />
          <img
            src="https://camo.githubusercontent.com/0daff31797957e2a6c802f165da685bdf8660bcd/68747470733a2f2f62616467656e2e6e65742f626164676573697a652f677a69702f68747470733a2f2f756e706b672e636f6d2f7374796c65642d74696479"
            alt="Gzip Size"
          />
          <img
            src="https://camo.githubusercontent.com/7c85b5a2541a0728ce57cc690f0e126306377267/68747470733a2f2f636f6465636f762e696f2f67682f6477322f7374796c65642d746964792f6272616e63682f6d61737465722f67726170682f62616467652e737667"
            alt="Codecov Report"
          />
        </Badges>
        <p>
          Styled-Components is a CSS-in-Javascript approach to building components in React. I love the approach and use it in every React project I start. My biggest gripe is how clunky the syntax appears when passing props inline. Tidy simplifies the common prop scenarios to make CSS more legible. It also includes media helpers and mixins, and increases developer happiness.
        </p>
      </div>
      {codeGrids.map(({ id, ref, name, blocks }) => (
        <div key={`group-${id}`}>
          <div id={id} ref={ref} />
          <h2>{name}</h2>
          <CodeGrid>
            {blocks.map(({ title, code }) => (
              <CodeCard
                key={`grid-${id}-${title}`}
                title={title}
                code={code.replace(/^\s{6}/gm, '')}
              />
            ))}
          </CodeGrid>
        </div>
      ))}
    </Project>
  )
}
