import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'

export default styled.p`
  ${flex('row', 'center', 'flex-start')}
  user-select: none;

  img {
    height: ${rem(20)};
    position: relative;
    margin: 0 ${rem(8)} 0 0;
    padding: 0;
    width: auto;

    :last-child {
      margin-right: 0;
    }
  }
`
