export default [
  {
    title: 'is',
    code:
      `const Example = styled.div\`
        \${is('enabled')\`
          display: block;
        \`}
      \`;`,
  },
  {
    title: 'isnt',
    code:
      `const Example = styled.div\`
        \${isnt('enabled')\`
          display: none;
        \`}
      \`;`,
  },
  {
    title: 'isAny',
    code:
      `const Example = styled.div\`
        \${isAny('size', ['sm', 'md'])\`
          display: block;
        \`}
      \`;`,
  },
  {
    title: 'isntAny',
    code:
      `const Example = styled.div\`
        \${isAny('size', ['lg', 'xl'])\`
          display: none;
        \`}
      \`;`,
  },
  {
    title: 'value',
    code:
      `const Example = styled.div\`
        width: \${value('size')};
      \`;`,
  },
  {
    title: 'swap',
    code:
      `const Example = styled.div\`
        opacity: \${swap('on', 1, 0)};
      \`;`,
  },
  {
    title: 'choose',
    code:
      `const sizes = {
        sm: 10, md: 20, lg: 30,
      };
      const Example = styled.div\`
        width:
          \${choose('size', sizes)}px;
      \`;`,
  },
  {
    title: 'over',
    code:
      `const Example = styled.div\`
        \${over('count', 420)\`
          color: green;
        \`}
      \`;`,
  },
  {
    title: 'under',
    code:
      `const Example = styled.div\`
        \${under('count', 420)\`
          color: red;
        \`}
      \`;`,
  },
  {
    title: 'between',
    code:
      `const Example = styled.div\`
        \${between('count', 5, 10)\`
          color: red;
        \`}
      \`;`,
  },
]
