import React from 'react'
import styled from 'styled-components'
import { rem, flex, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import stopEvent from 'lib/stop-event'
import BaseCard from 'components/base-card'
import DiagonalLine from 'components/diagonal-line'
import CodeBlock from 'components/code-block'

const { colors, fonts } = theme
const { primary, white, ghost, bone, black, syntax } = colors

export const Title = styled.span`
  ${flex('column', 'center', 'center')}
  ${position('absolute', 0)}
  z-index: 2;

  :after {
    ${position('absolute', 16)}
    border-radius: ${rem(6)};
    content: '';
    opacity: 0;
    transition: all 250ms ease;
  }

  span {
    background-color: ${black};
    border: ${rem(16)} solid ${ghost};
    border-radius: ${rem(19)};
    color: ${white};
    font-family: ${fonts.secondary};
    font-size: ${rem(18)};
    font-weight: 700;
    padding: 0 ${rem(8)};
    position: relative;
    transition: all 250ms ease;
    z-index: 2;

    ${media.small`
      font-size: ${rem(22)};
      padding: 0 ${rem(12)};
    `}

    ${media.medium`
      font-size: ${rem(24)};
    `}
  }
`

export const Code = styled.div`
  ${position('absolute', 0)}
  overflow: hidden;
  z-index: 3;

  > div {
    ${flex('column', 'flex-start', 'center')}
    ${position('absolute', 0)}
    background: ${syntax.background};
    border-radius: ${rem(4)};
    height: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: all 250ms ease;
  }

  ${media.large`
    .gatsby-highlight pre {
      font-size: ${rem(14)};
      overflow-y: hidden;
    }
  `}
`

const Card = styled(BaseCard)`
  background-color: ${ghost};
  border-radius: ${rem(4)};
  padding-bottom: 50%;

  ${media.small`
    padding-bottom: 60%;
  `}

  ${media.medium`
    padding-bottom: 50%;
  `}

  ${media.large`
    padding-bottom: 60%;
  `}
`

const Anchor = styled.a`
  :hover,
  :focus {
    ${Title} {
      :after {
        bottom: 0;
        border: ${rem(2)} solid ${bone};
        left: 0;
        opacity: 1;
        right: 0;
        top: 0;
      }

      span {
        background: ${primary};
        padding: 0 ${rem(16)} !important;
      }
    }
  }

  :focus {
    cursor: default;

    ${Code} > div {
      opacity: 1;
      transform: none;
    }
  }
`

export default ({ title, code }) => (
  <Card>
    <Anchor
      role="button"
      href="#"
      tabIndex={0}
      onClick={stopEvent}
      onKeyPress={stopEvent}
    >
      <Title>
        <DiagonalLine />
        <span>{title}</span>
      </Title>
      <Code>
        <CodeBlock label="js" lang="javascript" code={code} />
      </Code>
    </Anchor>
  </Card>
)
