import styled from 'styled-components'
import { rem, position } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { tundra } = theme.colors

export default styled.li`
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  flex-shrink: 0;
  text-align: center;
  user-select: none;
  width: 100%;

  svg {
    ${position('absolute', 16, 'auto', 0, 16)}
    height: calc(100% - ${rem(32)});
    width: calc(100% - ${rem(32)});

    line {
      stroke: ${tundra};
      stroke-width: 1;
    }
  }
`
