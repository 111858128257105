export default [
  {
    title: 'minWidth',
    code:
      `const Example = styled.div\`
        \${minWidth(800)\`
          font-size: 1.5rem;
        \`}
      \`;`,
  },
  {
    title: 'maxWidth',
    code:
      `const Example = styled.div\`
        \${maxWidth(800)\`
          font-size: 1rem;
        \`}
      \`;`,
  },
  {
    title: 'minHeight',
    code:
      `const Example = styled.div\`
        \${minHeight(420)\`
          position: sticky;
        \`}
      \`;`,
  },
  {
    title: 'maxHeight',
    code:
      `const Example = styled.div\`
        \${maxHeight(420)\`
          position: relative;
        \`}
      \`;`,
  },
]
